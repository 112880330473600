import axios from "axios";

export default {
    namespaced: true,
    state: {
        wishlists: [],
        total: 0,
        wishlist: "",
        relations: [],
    },
    getters: {
        wishlists(state) {
            return state.wishlists;
        },
        total(state) {
            return state.total;
        },
        wishlist(state) {
            return state.wishlist;
        },
        relations(state) {
            return state.relations;
        },
    },
    mutations: {
        SET_WISHLISTS(state, value) {
            state.wishlists = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_WISHLIST(state, value) {
            state.wishlist = value;
        },
        SET_RELATIONS(state, value) {
            state.relations = value;
        },
    },
    actions: {
        async wishlistsList({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/wishlists",
                    credentials
                );
                console.log(response.data.data);
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_WISHLISTS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add({ commit }, credentials) {
            try {
                let res = await axios.post("api/auth/wishlist/add", credentials);
                commit("SET_WISHLIST", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        // async delete(_, credentials) {
        //     try {
        //         await axios.post("api/auth/wishlist/delete", credentials);
        //     } catch (err) {
        //         console.log(err);
        //     }
        // },

        // async update(_, credentials) {
        //     try {
        //         await axios.post("api/auth/wishlist/update", credentials, {
        //             headers: {
        //                 "Content-Type": "multipart/form-data",
        //             },
        //         });
        //     } catch (err) {
        //         console.log(err);
        //     }
        // },

        async find({ commit }, credentials) {
            try {
                let res = await axios.post(
                    "api/auth/wishlist/find",
                    credentials
                );
                commit("SET_WISHLIST", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async relations({ commit }, credentials) {
            try {
                let res = await axios.post(
                    "api/auth/wishlist/relations",
                    credentials
                );
                commit("SET_RELATIONS", res.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        // async relationADD(_, credentials) {
        //     try {
        //         await axios.post("api/auth/wishlist/relation/add", credentials);
        //     } catch (err) {
        //         console.log(err);
        //     }
        // },
        // async relationDEL(_, credentials) {
        //     try {
        //         await axios.post(
        //             "api/auth/wishlist/relation/delete",
        //             credentials
        //         );
        //     } catch (err) {
        //         console.log(err);
        //     }
        // },
    },
};
