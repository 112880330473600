<template>
    <v-app light dense>
        <v-navigation-drawer
            v-model="drawer"
            :mini-variant="miniVariant"
            :clipped="clipped"
            fixed
            app
            dense
            color="#182A4D">
            <v-list-item>
                <v-list-item-content class="py-7">
                    <v-list-item-title class="text-h5 white--text">
                        <v-img
                            src="../assets/logo_hbd.png"
                            max-width="100"></v-img>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>
            <v-list>
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    :to="item.to"
                    router
                    exact
                    dense>
                    <v-list-item-action>
                        <v-icon color="#ddd">{{ item.icon }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title class="white--text">{{
                            item.title
                        }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar :clipped-left="clipped" dense fixed app elevation="0">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer" />

            <!-- <v-toolbar-title>{{ title }}</v-toolbar-title> -->
            <v-spacer />
            <v-btn icon @click="signout()">
                <v-icon>mdi-power</v-icon>
            </v-btn>
        </v-app-bar>
        <v-main>
            <v-container>
                <router-view />
            </v-container>
        </v-main>

        <div class="text-center ma-2">
            <v-snackbar v-model="snackbar" top right :color="color">
                {{ error }}
                <!-- <template v-slot:action="{ attrs }">
                    <v-btn
                        color="white"
                        text
                        v-bind="attrs"
                        @click="snackbarClose()">
                        Close
                    </v-btn>
                </template> -->
            </v-snackbar>
        </div>
    </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    middleware: "auth",
    name: "DefaultLayout",
    data() {
        return {
            clipped: false,
            drawer: true,
            fixed: false,
            timeout: 3000,
            items: [
                {
                    icon: "mdi-apps",
                    title: "Beranda",
                    to: "/v1/dashboard",
                },
                {
                    icon: "mdi-book-edit",
                    title: "Bookings",
                    to: "/v1/bookings",
                },
                {
                    icon: "mdi-account-group",
                    title: "Availability",
                    to: "/v1/availability",
                },

                {
                    icon: "mdi-book-edit",
                    title: "Tours",
                    to: "/v1/tours",
                },
                {
                    icon: "mdi-book-edit",
                    title: "Options",
                    to: "/v1/options",
                },
                {
                    icon: "mdi-book-edit",
                    title: "Destinations",
                    to: "/v1/destinations",
                },
                {
                    icon: "mdi-account-group",
                    title: "Guides",
                    to: "/v1/guides",
                },
                {
                     icon: "mdi-book-edit",
                     title: "Wishlists",
                     to: "/v1/wishlists",
                },
                {
                     icon: "mdi-book-edit",
                     title: "Transactions",
                     to: "/v1/transactions",
                },

                {
                    icon: "mdi-file-chart-outline",
                    title: "Report",
                    to: "/",
                },
                {
                    icon: "mdi-account-group",
                    title: "Users",
                    to: "/v1/users",
                },
                {
                    icon: "mdi-account-group",
                    title: "Black Periods",
                    to: "/v1/black_periods",
                },
            ],
            miniVariant: false,
            right: true,
            rightDrawer: false,
            title: "HBD Internal System v.1.0.0",
        };
    },

    mounted() {
        document.title = "HBD Internal System";
    },

    computed: {
        ...mapGetters({
            check: "auth/check",
            user: "auth/user",
            snackbar: "auth/snackbar",
            error: "auth/error",
            color: "auth/color",
        }),
    },

    methods: {
        ...mapActions({
            logoutAction: "auth/logout",
            getSnackbar: "auth/snackbar",
        }),

        async signout() {
            await this.logoutAction();
            this.$router.replace("/");
        },

        snackbarClose() {
            this.getSnackbar();
        },
    },
};
</script>
