import Vue from "vue";
import VueRouter from "vue-router";
import DashboardLayout from "@/layouts/DefaultLayout.vue";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "login",
        component: () =>
            import(
                /* webpackChunkName: "login" */ "../views/auth/LoginView.vue"
            ),
    },
    {
        path: "/forgot",
        name: "forgot",
        component: () =>
            import(
                /* webpackChunkName: "forgot" */ "../views/auth/ForgotView.vue"
            ),
    },
    {
        path: "/booking/:ref_id",
        name: "booking.ref",
        component: () =>
            import(
                /* webpackChunkName: "booking.ref" */ "../views/front/BookingView.vue"
            ),
    },
    {
        path: "/v1",
        component: DashboardLayout,
        children: [
            {
                path: "dashboard",
                name: "dashboard",
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ "../views/main/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "options",
                name: "options",
                component: () =>
                    import(
                        /* webpackChunkName: "options" */ "../views/options/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "option/add",
                name: "option.add",
                component: () =>
                    import(
                        /* webpackChunkName: "option.add" */ "../views/options/AddView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "option/update/:id",
                name: "option.update",
                component: () =>
                    import(
                        /* webpackChunkName: "option.update" */ "../views/options/UpdateView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "guides",
                name: "guides",
                component: () =>
                    import(
                        /* webpackChunkName: "guides" */ "../views/guides/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "guide/add",
                name: "guide.add",
                component: () =>
                    import(
                        /* webpackChunkName: "guide.add" */ "../views/guides/AddView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "guide/update/:id",
                name: "guide.update",
                component: () =>
                    import(
                        /* webpackChunkName: "guide.update" */ "../views/guides/UpdateView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "users",
                name: "users",
                component: () =>
                    import(
                        /* webpackChunkName: "guides" */ "../views/user/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "user/add",
                name: "user.add",
                component: () =>
                    import(
                        /* webpackChunkName: "guide.add" */ "../views/user/AddView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "user/update/:id",
                name: "user.update",
                component: () =>
                    import(
                        /* webpackChunkName: "guide.update" */ "../views/user/UpdateView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "tours",
                name: "tours",
                component: () =>
                    import(
                        /* webpackChunkName: "tours" */ "../views/tours/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "tour/add/:id",
                name: "tour.add",
                component: () =>
                    import(
                        /* webpackChunkName: "tour.add" */ "../views/tours/AddView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "bookings",
                name: "bookings",
                component: () =>
                    import(
                        /* webpackChunkName: "bookings" */ "../views/bookings/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "wishlists",
                name: "wishlists",
                component: () =>
                    import(
                        /* webpackChunkName: "wishlists" */ "../views/wishlists/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "wishlist/add/:id",
                name: "wishlist.add",
                component: () =>
                    import(
                        /* webpackChunkName: "wishlist.add" */ "../views/wishlists/AddView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "transactions",
                name: "transactions",
                component: () =>
                    import(
                        /* webpackChunkName: "bookings" */ "../views/transactions/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "availability",
                name: "availability",
                component: () =>
                    import(
                        /* webpackChunkName: "bookings" */ "../views/availability/AvailabilityGuide.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "destinations",
                name: "destinations",
                component: () =>
                    import(
                        /* webpackChunkName: "bookings" */ "../views/destination/IndexPage.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "black_periods",
                name: "black_periods",
                component: () =>
                    import(
                        /* webpackChunkName: "black_periods" */ "../views/black_periods/IndexView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "black_period/add",
                name: "black_period.add",
                component: () =>
                    import(
                        /* webpackChunkName: "black_period.add" */ "../views/black_periods/AddView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
            {
                path: "black_period/update/:id",
                name: "black_period.update",
                component: () =>
                    import(
                        /* webpackChunkName: "black_period.update" */ "../views/black_periods/UpdateView.vue"
                    ),
                meta: {
                    auth: true,
                },
            },
        ],
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.auth && !store.getters["auth/check"]) {
        next({ name: "login" });
    } else {
        next();
    }

    if (to.name == "login" && store.getters["auth/check"]) {
        next({ name: "dashboard" });
    } else {
        next();
    }
});

export default router;
