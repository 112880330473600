import axios from "axios";

export default {
    namespaced: true,
    state: {
        black_periods: [],
        black_periodsReady: [],
        total: 0,
        totalReady: 0,
        black_period: "",
        month: 0,
    },
    getters: {
        black_periods(state) {
            return state.black_periods;
        },
        black_periodsReady(state) {
            return state.black_periodsReady;
        },
        total(state) {
            return state.total;
        },
        totalReady(state) {
            return state.totalReady;
        },
        black_period(state) {
            return state.black_period;
        },
        month(state) {
            return state.month;
        },
    },
    mutations: {
        SET_BLACK_PERIODS(state, value) {
            state.black_periods = value;
        },
        SET_BLACK_PERIODS_READY(state, value) {
            state.black_periodsReady = value;
        },
        SET_TOTAL(state, value) {
            state.total = value;
        },
        SET_TOTAL_READY(state, value) {
            state.totalReady = value;
        },
        SET_BLACK_PERIOD(state, value) {
            state.black_period = value;
        },
        SET_MONTH(state, value) {
            state.month = value;
        },
    },
    actions: {
        changeMonth({ commit }, credentials) {
            commit("SET_MONTH", credentials.month);
        },
        async black_periodsList({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/black_periods",
                    credentials
                );
                commit("SET_TOTAL", response.data.data.total);
                commit("SET_BLACK_PERIODS", response.data.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async black_periodsReady({ commit }, credentials) {
            try {
                let response = await axios.post(
                    "/api/auth/black_periods/availability",
                    credentials
                );
                commit("SET_TOTAL_READY", response.data.total);
                commit("SET_BLACK_PERIODS_READY", response.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async add(_, credentials) {
            try {
                await axios.post("/api/auth/black_period/add", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async delete(_, credentials) {
            try {
                await axios.post("/api/auth/black_period/delete", credentials);
            } catch (err) {
                console.log(err);
            }
        },

        async find({ commit }, credentials) {
            try {
                let res = await axios.post("/api/auth/black_period/find", credentials);
                commit("SET_BLACK_PERIOD", res.data.data);
            } catch (err) {
                console.log(err);
            }
        },

        async update(_, credentials) {
            try {
                await axios.post("/api/auth/black_period/update", credentials);
            } catch (err) {
                console.log(err);
            }
        },
    },
};
