import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth";
import inclusion from "./inclusion";
import exclusion from "./exclusion";
import option from "./option";
import dashboard from "./dashboard";
import guide from "./guide";
import tour from "./tour";
import booking from "./booking";
import front from "./front";
import bill from "./bill";
import availability from "./availability";
import destination from "./destination";
import prices from "./prices";
import time from "./time";
import user from "./user";
import wishlist from "./wishlist";
import black_period from "./black_period";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: {
        auth,
        inclusion,
        exclusion,
        option,
        dashboard,
        guide,
        tour,
        booking,
        front,
        user,
        bill,
        availability,
        destination,
        prices,
        time,
        wishlist,
        black_period
    },
});
